@media screen and (min-width: 1960px) {
    .layout-main, .landing-wrapper {
        width: 1504px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        justify-content: center !important;
    }

}

@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 300px;
            }

            &.layout-static-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding-left: 2rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 998;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
                animation: fadein $transitionDuration;
            }
        }
    }
}
