/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

.layout-footer {
    border-top: none !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Avoid manual resizing of textarea */
textarea {
    resize: none !important;
}

a:hover{
    text-decoration: underline;
}

#join-us-button{
    background-color: var(--secondary-color) !important;
}

/* Header images */
#hero {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#main-search {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#companies {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#scientists {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#news {
    background: url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#research-problems {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

#equipment {
    background: #0c386c url('assets/layout/images/header-01.jpg') 100% no-repeat;
    content: " ";
    z-index: 2;
    background-size: cover;
    min-height: 250px;
    justify-content: center;
}

.simple-entity-card-button{
    width: 100%;
    font-weight: 900 !important;
}

.simple-entity-card-button:hover{
    background: var(--secondary-color) !important;
    color: #ffffff !important;
}

.landing-wrapper{
    background: #e6e8ec;
}

.label-icon{
    font-weight: bolder !important;
    color: #001b71 !important;
}

.helper-text{
    margin: 0.5rem;
    color: #00000087;
}

.card{
    border-radius: 0 !important;
}

/* --start-- LOADING OVERLAY --start-- */

.spinner-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.59);
    z-index: 1002;

    p-progressSpinner {
        width: 6rem;
        height: 6rem;
    }
}

.p-progress-spinner-circle {
    stroke: var(--primary-color) !important;
}

/* --end-- LOADING OVERLAY --end-- */


